import Iconify from "@/Components/iconify/Iconify";
import SvgColor from "@/Components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor
        src={`/storage/assets/icons/navbar/${name}.svg`}
        sx={{ width: 1, height: 1 }}
    />
);

const ICONS = {
    dashboard: <Iconify icon="mage:dashboard-check" />,
    home: <Iconify icon="mdi:search" />,
    inbox: <Iconify icon="mdi:chat-processing-outline" />,
    feeds: <Iconify icon="fluent:news-24-regular" />,
    jobs: <Iconify icon="uil:bag" />,
    candidates: <Iconify icon="ph:users-bold" />,
};

const navConfig = [
    {
        items: [
            {
                title: "Dashboard",
                path: route("home.dashboard"),
                icon: ICONS.dashboard,
                routeNames: ["home.dashboard"],
            },
        ],
    },

    {
        items: [
            {
                title: "Hiring Pipeline",
                path: route("application.pipeline"),
                icon: ICONS.candidates,
                routeNames: ["application.pipeline"],
            },
        ],
    },

    {
        items: [
            {
                title: "Search",
                path: route("home"),
                icon: ICONS.home,
                routeNames: ["home"],
            },
        ],
    },
    {
        items: [
            {
                title: "Inbox",
                path: route("messaging.inbox"),
                icon: ICONS.inbox,
                routeNames: ["messaging.inbox"],
            },
        ],
    },
    {
        items: [
            {
                title: "Feeds",
                path: route("feed.index"),
                icon: ICONS.feeds,
                routeNames: ["feed.index"],
            },
        ],
    },
    {
        items: [
            {
                title: "Jobs",
                path: route("job.index"),
                icon: ICONS.jobs,
                routeNames: ["job.index"],
            },
        ],
    },
    // {
    //     items: [
    //         {
    //             title: "Test Job",
    //             path: route("job.testPostedJob"),
    //             icon: ICONS.jobs,
    //             routeNames: ["job.testPostedJob"],
    //         },
    //     ],
    // },
    // {
    //     items: [
    //         {
    //             title: "Candidates",
    //             path: route("candidates.index"),
    //             icon: ICONS.candidates,
    //             routeNames: ["candidates.index"],
    //         },
    //     ],
    // },
];

export default navConfig;
